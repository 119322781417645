import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Decentralized Autonomous Mutual Fund',
    subtext: 'React | Tailwind | Amplify | Cognito',
    github: 'https://github.com',
    demo: ''
  },
  {
    id: 2,
    image: IMG2,
    title: 'Bullish Bears Club Website',
    subtext: 'React | Tailwind',
    github: 'https://github.com',
    demo: 'https://spontaneous-capybara-273468.netlify.app'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Flappy Bear Game',
    subtext: 'JavaScript | Bootstrap',
    github: 'https://github.com',
    demo: 'https://luxury-stardust-897774.netlify.app/'
  },
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
          <article className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={IMG1} className="scale-75"/>
              </div>
              <h3>Decentralized Autonomous Mutual Fund</h3>
              <h5>React | Tailwind | Amplify | Cognito</h5>
              <div className="portfolio__item-cta">
                {/* <button href="gitlink" className='btn' target='_blank' disabled>Github</button> */}
                <a href="portfolio" className='btn btn-primary' target='_blank'>Live Demo Unavailable (Privacy)</a>
              </div>
          </article>
          <article className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={IMG2} className="scale-75"/>
              </div>
              <h3>Bullish Bears Club Website</h3>
              <h5>React | Tailwind</h5>
              <div className="portfolio__item-cta">
                {/* <button href="getlink" className='btn' target='_blank' disabled>Github</button> */}
                <a href="https://spontaneous-capybara-273468.netlify.app" className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
          </article>
          <article className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={IMG3} className="scale-75"/>
              </div>
              <h3>Flappy Bear Game</h3>
              <h5>JavaScript | Bootstrap</h5>
              <div className="portfolio__item-cta">
                {/* <button href="getlink" className='btn' target='_blank' disabled>Github</button> */}
                <a href="https://luxury-stardust-897774.netlify.app/" className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
          </article>
      </div>
    </section>
  )
}

export default Portfolio