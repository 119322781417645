import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/1519357835377.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR1,
    name: 'Saurabh Dahal',
    review: "I had the pleasure of being Victor's instructor for Java and MERN courses at a very intense coding bootcamp, Coding Dojo. Victor achieved the highest levels of proficiency in the courses taught at Coding Dojo, with the mark of 'Triple Black Belt', meaning 10/10 on all three of his full stack courses. Victor displayed excellent capacity and competency to learn programming concepts quickly to apply what he learned in web development projects. On average the workload requires about 60-70 hours per week of commitment for almost 4 months straight. Victor easily exceeded these expectations consistently. He even has built additional projects to his portfolio. Lastly, Victor is very fun to work with and teach. His classmates always had a great time working with him and he often is seen helping other students who are behind. He is a true team player and makes the work environment around him fun because of his passion for coding, learning, building, and solving problems. I recommend Victor to software development teams looking for a very competent engineer who can easily learn the tools and stacks necessary for the role."
  },
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]} spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials